import React from 'react'
import '../App.css';
import NavBar from '../components/NavBar';
import Owner from '../components/Owner';
import Footer from '../components/Footer';

function Ownerpage() {
    return (
      <>
      <NavBar/>
      <Owner/>
      <Footer/>
      </>
    )
}
export default Ownerpage