import React from "react";
import { VscTools } from 'react-icons/vsc'

function Publishersec() {
  return (
    <>
      <div className="spacer">     </div>
      <div class="bg-image d-flex justify-content-center align-items-center">
        <h1 class="text-white publisher-header">FUTURE OF MONETISATION
          BUILT FOR YOU</h1>
      </div>
      <div class="py-5 text-justify container publishers">
        <div class="row py-lg-5 ">
          <p>Ookami Media is a leading Ad Network, which provides publishers with a completely integrated monetization platform to help them build an audience and grow their business. Our program works seamlessly across devices, whether mobile or desktop and hence you don’t need to look for multiple partners for each device.</p><br></br>
          <p>Our platform is carefully designed to improve your revenue, eCPM and yield by reducing any revenue leakage, enhancing ad performance and improving the user-experience.</p><br></br>
          <p>Ookami Media makes integration to our exclusive marketplace extremely simple and allows publishers to monetize their content easily and conveniently.</p><br></br>
          <p>We support multivariate ad types and omnichannel advertising so that you never miss an opportunity to increase your yield and profits in tandem.</p>
          <p>We provide you with quality demand and enhancement technology that respects your users by improving the ad experience.</p>


        </div>
      </div>
      {/* Advertising Products */}
      <div class="publisher-product-bg">
        <div class="publisher-product-heading text-center md:text-4xl text-2xl font-bold md:mt-14">
          <h1>EXPLORE OUR PROGRAMMATIC ADVERTISING PRODUCTS FOR PUBLISHERS</h1>
        </div>
        <div className='md:h-[65vh] px-6 mb-8 flex flex-col md:flex-row justify-center items-center'>

          <a href='#ad' className='md:mx-6 mx-1 my-5 md:my-0 md:w-1/3 md:h-[90%] rounded-xl flex flex-col items-center  shadow-[0_0px_40px_5px_rgba(0,0,0,0.1)] hover:bg-gradient-to-br from-primary  hover:text-white'>

            <div className='p-4 m-6 bg-gray-200 rounded-full'> 
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_68_26389)">
    <path d="M18.003 16.827C16.9699 16.827 15.913 16.5538 15.248 15.6513C14.9392 15.1645 14.9749 14.7845 15.058 14.5588C15.3074 13.8938 16.1267 13.6326 17.0055 13.3595C17.4448 13.217 17.908 13.0745 18.2405 12.8963C18.4067 12.8132 18.5611 12.7182 18.7036 12.6351C19.7486 12.0413 20.8292 11.412 22.3492 12.172C23.3705 12.6826 24.2373 12.9557 24.5936 13.0507L26.7073 12.2076C27.0042 12.0888 27.3605 12.2313 27.4792 12.5401C27.598 12.8488 27.4555 13.1932 27.1467 13.312L24.8549 14.2263C24.748 14.2738 24.6173 14.2857 24.4986 14.2501C24.4511 14.2501 23.2874 13.9651 21.8149 13.2288C20.9005 12.7776 20.3186 13.0626 19.2855 13.6563C19.1311 13.7513 18.9648 13.8463 18.7867 13.9295C18.3474 14.167 17.8367 14.3213 17.338 14.4757C16.9342 14.6063 16.2574 14.8201 16.1386 14.9745C17.2549 16.4113 20.568 15.1051 20.6036 15.0932C20.9124 14.9745 21.2567 15.117 21.3755 15.4138C21.4942 15.7107 21.3517 16.067 21.0548 16.1857C20.9598 16.2332 19.4874 16.8151 17.9792 16.8151L18.003 16.827Z" fill="#4D4DFF"/>
    <path d="M32.5848 21.7193C32.3948 21.7193 32.1929 21.6243 32.086 21.4462L25.8754 11.7562C25.6973 11.4831 25.7804 11.1149 26.0535 10.9368L29.996 8.40743C30.1266 8.3243 30.2929 8.28868 30.4473 8.3243C30.6016 8.35993 30.7323 8.45493 30.8154 8.58555L37.026 18.2756C37.1091 18.4062 37.1448 18.5724 37.1091 18.7268C37.0735 18.8812 36.9785 19.0118 36.8479 19.0949L32.9054 21.6243C32.8104 21.6837 32.6916 21.7193 32.5848 21.7193ZM27.1935 11.6137L32.7629 20.3062L35.7079 18.4181L30.1385 9.72555L27.1935 11.6137Z" fill="#4D4DFF"/>
    <path d="M5.41508 21.7193C5.30821 21.7193 5.18946 21.6837 5.09446 21.6243L1.15196 19.0949C1.02133 19.0118 0.926334 18.8812 0.890709 18.7268C0.855084 18.5724 0.890709 18.4181 0.973834 18.2756L7.18446 8.58555C7.26758 8.45493 7.39821 8.35993 7.55258 8.3243C7.70696 8.28868 7.86133 8.3243 8.00383 8.40743L11.9463 10.9368C12.2195 11.1149 12.3026 11.4831 12.1245 11.7562L5.91383 21.4462C5.79508 21.6243 5.60508 21.7193 5.41508 21.7193ZM2.29196 18.4181L5.23696 20.3062L10.8063 11.6137L7.86133 9.72555L2.29196 18.4181Z" fill="#4D4DFF"/>
    <path d="M28.9863 22.574C28.832 22.574 28.6776 22.5146 28.5707 22.3959C28.3332 22.1584 28.3332 21.7902 28.5707 21.5527L30.7676 19.3559C31.0051 19.1184 31.3732 19.1184 31.6107 19.3559C31.8482 19.5934 31.8482 19.9615 31.6107 20.199L29.4138 22.3959C29.2951 22.5146 29.1407 22.574 28.9982 22.574H28.9863Z" fill="#4D4DFF"/>
    <path d="M13.1571 13.6447C11.9459 13.6447 10.9721 13.5378 10.9128 13.5378C10.5921 13.5022 10.3546 13.2053 10.3903 12.8847C10.4259 12.5522 10.7228 12.3147 11.0434 12.3622C11.0671 12.3622 13.8578 12.6591 15.8171 12.2316C18.0615 11.7328 19.534 12.3384 19.5934 12.3622C19.8903 12.4928 20.0328 12.8372 19.9021 13.1459C19.7715 13.4428 19.4271 13.5853 19.1303 13.4666C19.0946 13.4547 17.9071 12.9916 16.0665 13.3953C15.1403 13.5972 14.0715 13.6566 13.1571 13.6566V13.6447Z" fill="#4D4DFF"/>
    <path d="M8.19456 22.1107C8.06394 22.1107 7.93331 22.0632 7.81456 21.9801L6.23519 20.6739C5.98581 20.4601 5.95019 20.092 6.15206 19.8426C6.36581 19.5932 6.73394 19.5576 6.98331 19.7595L8.56269 21.0657C8.81206 21.2795 8.84769 21.6476 8.64581 21.897C8.52706 22.0395 8.36081 22.1107 8.18269 22.1107H8.19456Z" fill="#4D4DFF"/>
    <path d="M20.817 29.5927C20.4251 29.5927 20.0332 29.462 19.7007 29.2245L18.0857 28.0489C17.8245 27.8589 17.7651 27.4908 17.9551 27.2177C18.1451 26.9564 18.5251 26.897 18.7864 27.087L20.4014 28.2627C20.5557 28.3814 20.7457 28.417 20.9357 28.3933C21.1257 28.3577 21.292 28.2627 21.3989 28.1083C21.6364 27.7877 21.5651 27.3364 21.2445 27.0989L18.4064 24.902C18.1451 24.7002 18.0976 24.332 18.2995 24.0708C18.5014 23.8095 18.8695 23.762 19.1307 23.9639L21.957 26.1489C22.7882 26.7545 22.9782 27.9539 22.3607 28.797C22.0639 29.2127 21.6245 29.4858 21.1139 29.557C21.0189 29.5689 20.912 29.5808 20.8051 29.5808L20.817 29.5927Z" fill="#4D4DFF"/>
    <path d="M23.6439 28.2861C23.2521 28.2861 22.8602 28.1673 22.5277 27.918L20.8889 26.8136C20.6158 26.6355 20.5446 26.2673 20.7227 25.9942C20.9127 25.7211 21.2808 25.6498 21.5421 25.828L23.2046 26.9442C23.5489 27.1817 23.9883 27.1223 24.2258 26.8017C24.3327 26.6473 24.3802 26.4573 24.3564 26.2673C24.3327 26.0773 24.2258 25.9111 24.0714 25.7923L19.6658 22.5742C19.4046 22.3842 19.3452 22.0042 19.5352 21.743C19.7371 21.4817 20.1052 21.4223 20.3664 21.6123L24.7721 24.8305C25.1877 25.1273 25.4489 25.5667 25.5321 26.0773C25.6152 26.588 25.4964 27.0867 25.1877 27.5023C24.8196 28.013 24.2377 28.2861 23.6439 28.2861Z" fill="#4D4DFF"/>
    <path d="M25.733 26.5759C25.3411 26.5759 24.9493 26.4572 24.6168 26.2197L23.0018 25.0441C22.7405 24.8541 22.6811 24.4741 22.8711 24.2128C23.0611 23.9516 23.4411 23.8922 23.7024 24.0822L25.3174 25.2578C25.638 25.4953 26.0893 25.4241 26.3268 25.1034C26.5643 24.7828 26.4811 24.3316 26.1605 24.0941L20.8761 20.1634C20.6149 19.9616 20.5555 19.5934 20.7574 19.3322C20.9593 19.0709 21.3274 19.0116 21.5886 19.2134L26.8611 23.1441C27.7043 23.7616 27.8943 24.9491 27.2768 25.8041C26.9086 26.3147 26.3149 26.5878 25.733 26.5878V26.5759Z" fill="#4D4DFF"/>
    <path d="M27.3845 24.4857C26.9927 24.4857 26.6008 24.367 26.2683 24.1295L24.6533 22.9539C24.392 22.7639 24.3327 22.3839 24.5227 22.1226C24.7127 21.8614 25.0927 21.802 25.3539 21.992L26.9689 23.1676C27.2895 23.4051 27.7408 23.3339 27.9783 23.0132C28.2158 22.6926 28.1327 22.2414 27.812 22.0039L20.5683 16.0782C20.3189 15.8764 20.2714 15.4964 20.4852 15.247C20.6989 14.9976 21.067 14.9501 21.3164 15.1639L28.5364 21.0657C29.3558 21.6595 29.5458 22.8589 28.9283 23.702C28.5602 24.2126 27.9664 24.4857 27.3845 24.4857Z" fill="#4D4DFF"/>
    <path d="M15.9597 29.7235C15.9597 29.7235 15.8885 29.7235 15.8647 29.7235C15.4372 29.6997 15.0335 29.5097 14.7485 29.1772L14.2616 28.631C13.6679 27.966 13.7391 26.9447 14.4041 26.351L15.6866 25.211C16.3516 24.6172 17.3729 24.6766 17.9666 25.3535L18.4535 25.8997C18.7385 26.2203 18.881 26.636 18.8572 27.0635C18.8335 27.491 18.6435 27.8947 18.311 28.1797L17.0285 29.3197C16.7316 29.581 16.3516 29.7235 15.9597 29.7235ZM16.7554 25.9947C16.6604 25.9947 16.5535 26.0303 16.4704 26.1016L15.1879 27.2416C15.0097 27.396 14.9979 27.6691 15.1522 27.8472L15.6391 28.3935C15.7104 28.4766 15.8172 28.536 15.936 28.536C16.0547 28.5597 16.1616 28.5003 16.2447 28.4291L17.5272 27.2891C17.6104 27.2178 17.6579 27.111 17.6697 26.9922C17.6816 26.8735 17.6341 26.7666 17.5629 26.6835L17.076 26.1372C16.9929 26.0422 16.8741 25.9947 16.7554 25.9947Z" fill="#4D4DFF"/>
    <path d="M13.206 28.4521C13.206 28.4521 13.1347 28.4521 13.111 28.4521C12.6835 28.4284 12.2797 28.2384 11.9947 27.9059L11.5079 27.3596C11.2229 27.039 11.0804 26.6234 11.1041 26.1959C11.1279 25.7684 11.3179 25.3646 11.6504 25.0796L14.0372 22.9659C14.3579 22.6809 14.7735 22.5384 15.201 22.5621C15.6285 22.5859 16.0204 22.7759 16.3172 23.1084L16.8041 23.6546C17.3979 24.3196 17.3385 25.3409 16.6616 25.9346L14.2747 28.0484C13.9779 28.3096 13.5979 28.4521 13.206 28.4521ZM15.106 23.7496C14.9991 23.7496 14.9041 23.7852 14.821 23.8565L12.4341 25.9702C12.256 26.1246 12.2441 26.3977 12.3985 26.5759L12.8854 27.1221C12.9566 27.2052 13.0635 27.2646 13.1822 27.2646C13.2891 27.2884 13.4079 27.229 13.491 27.1577L15.8779 25.044C15.961 24.9727 16.0085 24.8659 16.0204 24.7471C16.0322 24.6284 15.9847 24.5215 15.9135 24.4384L15.4266 23.8921C15.3554 23.809 15.2485 23.7615 15.1297 23.7496C15.1297 23.7496 15.1179 23.7496 15.106 23.7496Z" fill="#4D4DFF"/>
    <path d="M10.9853 26.7187C10.534 26.7187 10.0947 26.5405 9.77404 26.1724L9.28716 25.6262C9.00216 25.3055 8.85966 24.8899 8.88341 24.4624C8.90716 24.0349 9.09716 23.6312 9.42966 23.3462L12.0897 20.983C12.4103 20.698 12.8259 20.5437 13.2534 20.5793C13.6809 20.603 14.0728 20.793 14.3578 21.1255L14.8447 21.6718C15.4384 22.3368 15.3672 23.358 14.7022 23.9518L12.0422 26.3149C11.7334 26.588 11.3534 26.7187 10.9734 26.7187H10.9853ZM13.1584 21.7668C13.0515 21.7668 12.9565 21.8024 12.8734 21.8737L10.2134 24.2368C10.1303 24.308 10.0709 24.4149 10.0709 24.5337C10.0709 24.6524 10.1065 24.7593 10.1778 24.8424L10.6647 25.3887C10.819 25.5668 11.0922 25.5787 11.2703 25.4243L13.9303 23.0612C14.1084 22.9068 14.1203 22.6337 13.9659 22.4555L13.479 21.9093C13.4078 21.8262 13.3009 21.7668 13.1822 21.7668C13.1822 21.7668 13.1703 21.7668 13.1584 21.7668Z" fill="#4D4DFF"/>
    <path d="M9.09644 24.6881C9.09644 24.6881 9.02519 24.6881 9.00144 24.6881C8.57394 24.6643 8.17019 24.4743 7.88519 24.1418L7.39832 23.5956C6.80457 22.9306 6.87582 21.9093 7.54082 21.3156L9.32207 19.7362C9.98707 19.1425 11.0083 19.2137 11.6021 19.8787L12.0889 20.425C12.3739 20.7456 12.5164 21.1612 12.4927 21.5887C12.4689 22.0162 12.2789 22.42 11.9464 22.705L10.1652 24.2843C9.86832 24.5456 9.48832 24.6881 9.09644 24.6881ZM10.3908 20.52C10.2958 20.52 10.1889 20.5556 10.1058 20.6268L8.32457 22.2062C8.14644 22.3606 8.13457 22.6337 8.28894 22.8118L8.77582 23.3581C8.84707 23.4412 8.95394 23.5006 9.07269 23.5006C9.19144 23.5125 9.29832 23.465 9.38144 23.3937L11.1627 21.8143C11.2458 21.7431 11.2933 21.6362 11.3052 21.5175C11.3171 21.3987 11.2696 21.2918 11.1983 21.2087L10.7114 20.6625C10.6283 20.5675 10.5096 20.52 10.3908 20.52Z" fill="#4D4DFF"/>
  </g>
  <defs>
    <clipPath id="clip0_68_26389">
      <rect width="38" height="38" fill="white"/>
    </clipPath>
  </defs>
</svg>
            </div>
            <h1 className='md:text-2xl text-2xl font-bold px-4 text-center'>Premium Demand Partners</h1>
            <p className='text-center md:mx-9 mx-3 my-9 md:text-xl text-md'>
            Make sure your site visitors are getting the best experience by tapping into demand from the world’s best brands.
            </p>
            <a className='font-medium text-white bg-primary px-4 py-2 mb-6 rounded-3xl text-md hover:bg-white hover:text-primary border border-primary' href="#aboutus"  rel='noreferrer' >Learn More</a>

          </a>

          <a href='#services' className='md:mx-6 mx-1 my-5 md:my-0 md:w-1/3 h-[90%] rounded-xl flex flex-col items-center  shadow-[0_0px_40px_5px_rgba(0,0,0,0.1)] hover:bg-gradient-to-br from-primary  hover:text-white'>

            <div className='p-4 m-6 bg-gray-200 rounded-full'> <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_68_26421)">
    <path d="M18.9987 21.2113C14.7908 21.2113 11.3613 17.7818 11.3613 13.5739C11.3613 9.36602 14.7908 5.93652 18.9987 5.93652C23.2066 5.93652 26.6361 9.36602 26.6361 13.5739C26.6361 17.7818 23.2066 21.2113 18.9987 21.2113ZM18.9987 7.06465C15.4113 7.06465 12.4895 9.98649 12.4895 13.5739C12.4895 17.1614 15.4113 20.0832 18.9987 20.0832C22.5862 20.0832 25.508 17.1614 25.508 13.5739C25.508 9.98649 22.5862 7.06465 18.9987 7.06465Z" fill="#4D4DFF"/>
    <path d="M31.149 35.3583H6.84922C6.53334 35.3583 6.28516 35.1101 6.28516 34.7942C6.28516 29.0182 11.9935 24.3252 18.9991 24.3252C26.0048 24.3252 31.7131 29.0182 31.7131 34.7942C31.7131 35.1101 31.4649 35.3583 31.149 35.3583ZM7.42456 34.2301H30.5624C30.2014 29.3454 25.1474 25.4533 18.9991 25.4533C12.8508 25.4533 7.79684 29.3454 7.43584 34.2301H7.42456Z" fill="#4D4DFF"/>
    <path d="M9.87197 17.3869H8.55206C6.96141 17.3869 5.66406 16.0895 5.66406 14.4989V12.6487C5.66406 11.0581 6.96141 9.76074 8.55206 9.76074H9.87197C10.1878 9.76074 10.436 10.0089 10.436 10.3248V16.8228C10.436 17.1387 10.1878 17.3869 9.87197 17.3869ZM8.55206 10.8889C7.58188 10.8889 6.79219 11.6786 6.79219 12.6487V14.4989C6.79219 15.4691 7.58188 16.2587 8.55206 16.2587H9.30791V10.8889H8.55206Z" fill="#4D4DFF"/>
    <path d="M29.4445 17.3869H28.1246C27.8087 17.3869 27.5605 17.1387 27.5605 16.8228V10.3248C27.5605 10.0089 27.8087 9.76074 28.1246 9.76074H29.4445C31.0352 9.76074 32.3325 11.0581 32.3325 12.6487V14.4989C32.3325 16.0895 31.0352 17.3869 29.4445 17.3869ZM28.6887 16.2587H29.4445C30.4147 16.2587 31.2044 15.4691 31.2044 14.4989V12.6487C31.2044 11.6786 30.4147 10.8889 29.4445 10.8889H28.6887V16.2587Z" fill="#4D4DFF"/>
    <path d="M22.1794 20.2193C20.1488 20.2193 18.1069 19.3844 16.8434 18.0307C16.6291 17.8051 16.6403 17.4441 16.866 17.2297C17.0916 17.0154 17.4526 17.0267 17.6669 17.2523C18.5017 18.1435 20.1262 19.0798 22.1794 19.0798H22.2133C23.6122 19.0798 25.7105 18.606 27.7185 16.4288C27.9329 16.1918 28.2826 16.1806 28.5195 16.3949C28.7564 16.6093 28.7677 16.959 28.5533 17.1959C26.2632 19.6778 23.8491 20.208 22.2246 20.208H22.1907L22.1794 20.2193Z" fill="#4D4DFF"/>
    <path d="M28.1257 17.3872C27.8098 17.3872 27.5617 17.139 27.5617 16.8231V12.3445C27.5617 7.61761 23.7147 3.78198 18.9992 3.78198C14.2836 3.78198 10.4367 7.62889 10.4367 12.3445V16.8231C10.4367 17.139 10.1885 17.3872 9.87266 17.3872C9.55678 17.3872 9.30859 17.139 9.30859 16.8231V12.3445C9.30859 6.99714 13.6519 2.64258 18.9992 2.64258C24.3465 2.64258 28.6898 6.99714 28.6898 12.3332V16.8118C28.6898 17.1277 28.4416 17.3759 28.1257 17.3759V17.3872Z" fill="#4D4DFF"/>
  </g>
  <defs>
    <clipPath id="clip0_68_26421">
      <rect width="36.1" height="36.1" fill="white" transform="translate(0.949219 0.950195)"/>
    </clipPath>
  </defs>
</svg>
 </div>
            <h1 className='md:text-2xl text-2xl font-bold px-4 text-center'>Omnichannel Marketplace</h1>
            <p className='text-center md:mx-9 mx-3 my-9 md:text-xl text-md'>
            Sell channel specific media through our unified cross-channel marketplace for ease of discovery and added sales opportunities.
            </p>
            <a className='font-medium text-white bg-primary px-4 py-2 mb-6 rounded-3xl text-md hover:bg-white hover:text-primary border border-primary' href="#aboutus" rel='noreferrer' >Learn More</a>
          </a>

          <a href='#appm' className='md:mx-6 mx-1 my-5 md:my-0 md:w-1/3 h-[90%] rounded-xl flex flex-col items-center  shadow-[0_0px_40px_5px_rgba(0,0,0,0.1)] hover:bg-gradient-to-br from-primary  hover:text-white'>

            <div className='p-4 m-6 bg-gray-200 rounded-full'> 
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_68_26461)">
    <path d="M7.80164 18.7029C5.07039 18.7029 2.83789 16.4823 2.83789 13.7391C2.83789 10.996 5.05852 8.77539 7.80164 8.77539C10.5448 8.77539 12.7654 10.996 12.7654 13.7391C12.7654 16.4823 10.5448 18.7029 7.80164 18.7029ZM7.80164 9.97477C5.72352 9.97477 4.02539 11.6729 4.02539 13.751C4.02539 15.8291 5.71164 17.5273 7.80164 17.5273C9.89164 17.5273 11.5779 15.841 11.5779 13.751C11.5779 11.661 9.87977 9.97477 7.80164 9.97477Z" fill="#4D4DFF"/>
    <path d="M11.3881 31.2431H2.375C2.0425 31.2431 1.78125 30.9819 1.78125 30.6494C1.78125 27.1463 2.64812 24.51 4.37 22.8238C5.9375 21.28 8.2175 20.5319 11.115 20.5675C13.4069 20.615 15.7581 20.4013 16.1619 18.6081C16.2331 18.2875 16.5537 18.0856 16.8744 18.1569C17.195 18.2281 17.3969 18.5488 17.3256 18.8694C16.6487 21.85 12.8131 21.7788 11.1625 21.7431C8.56187 21.7075 6.53125 22.3369 5.20125 23.655C3.78812 25.0444 3.06375 27.1344 2.98062 30.0438H11.4C11.7325 30.0438 11.9937 30.305 11.9937 30.6375C11.9937 30.97 11.7325 31.2313 11.4 31.2313L11.3881 31.2431Z" fill="#4D4DFF"/>
    <path d="M30.2567 18.7029C27.5255 18.7029 25.293 16.4823 25.293 13.7391C25.293 10.996 27.5136 8.77539 30.2567 8.77539C32.9998 8.77539 35.2205 10.996 35.2205 13.7391C35.2205 16.4823 32.9998 18.7029 30.2567 18.7029ZM30.2567 9.97477C28.1786 9.97477 26.4805 11.6729 26.4805 13.751C26.4805 15.8291 28.1786 17.5273 30.2567 17.5273C32.3348 17.5273 34.033 15.841 34.033 13.751C34.033 11.661 32.3467 9.97477 30.2567 9.97477Z" fill="#4D4DFF"/>
    <path d="M35.6245 31.2424H27.4901C27.1576 31.2424 26.8964 30.9812 26.8964 30.6487C26.8964 30.3162 27.1576 30.0549 27.4901 30.0549H35.0307C35.007 26.1837 34.6389 22.0393 26.8726 21.7543C22.3245 21.5762 18.8451 18.8687 18.4176 15.1518C18.382 14.8312 18.6195 14.5343 18.9401 14.4987C19.2607 14.463 19.5576 14.7005 19.5932 15.0212C19.9495 18.1324 22.9657 20.4243 26.9201 20.5668C36.2301 20.923 36.2301 26.7774 36.2301 30.6487C36.2301 30.9812 35.9689 31.2424 35.6364 31.2424H35.6245Z" fill="#4D4DFF"/>
    <path d="M19.166 10.5331C18.8335 10.5331 18.5723 10.2718 18.5723 9.93934V7.35059C18.5723 7.01809 18.8335 6.75684 19.166 6.75684C19.4985 6.75684 19.7598 7.01809 19.7598 7.35059V9.93934C19.7598 10.2718 19.4985 10.5331 19.166 10.5331Z" fill="#4D4DFF"/>
    <path d="M22.0398 12.2311C21.9448 12.2311 21.8498 12.2073 21.7667 12.1598C21.4817 12.0055 21.3629 11.6492 21.5173 11.3523L22.7285 9.07234C22.8829 8.78734 23.2392 8.66859 23.536 8.82296C23.821 8.97734 23.9398 9.33359 23.7854 9.63046L22.5742 11.9105C22.4673 12.1123 22.2654 12.2311 22.0517 12.2311H22.0398Z" fill="#4D4DFF"/>
    <path d="M16.079 12.1002C15.8771 12.1002 15.6752 11.9933 15.5565 11.8033L14.2858 9.54706C14.1196 9.26206 14.2265 8.90581 14.5115 8.73956C14.7965 8.58518 15.1527 8.68018 15.319 8.96518L16.5896 11.2214C16.7558 11.5064 16.649 11.8627 16.364 12.0289C16.269 12.0764 16.174 12.1002 16.0671 12.1002H16.079Z" fill="#4D4DFF"/>
  </g>
  <defs>
    <clipPath id="clip0_68_26461">
      <rect width="38" height="38" fill="white"/>
    </clipPath>
  </defs>
</svg>
</div>
            <h1 className='md:text-2xl text-2xl font-bold px-4 text-center'>Privacy Friendly</h1>
            <p className='text-center md:mx-9 mx-3 my-9 md:text-xl text-md'>
            We support leading consumer privacy initiatives including GDPR and CCPA.
            </p>
            <a className='font-medium text-white bg-primary px-4 py-2 mb-6 rounded-3xl text-md hover:bg-white hover:text-primary border border-primary' href="#" target="_blank" rel='noreferrer' >Learn More</a>
          </a>
          
        </div>
{/* second row */}
        <div className='md:h-[60vh] px-6 mb-8 flex flex-col md:flex-row justify-center items-center'>

          <a href='#ad' className='md:mx-6 mx-1 my-5 md:my-0 md:w-1/3 md:h-[90%] rounded-xl flex flex-col items-center  shadow-[0_0px_40px_5px_rgba(0,0,0,0.1)] hover:bg-gradient-to-br from-primary  hover:text-white'>

            <div className='p-4 m-6 bg-gray-200 rounded-full'> <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_68_26491)">
    <path d="M16.7913 34.0102C8.51438 34.0102 1.78125 27.2771 1.78125 19.0002C1.78125 10.7234 8.51438 3.99023 16.7913 3.99023C19.7719 3.99023 22.6575 4.86898 25.1275 6.51961C25.4006 6.69773 25.4719 7.06586 25.2938 7.33898C25.1156 7.61211 24.7475 7.68336 24.4744 7.50523C22.1944 5.98523 19.5463 5.17773 16.7913 5.17773C9.1675 5.17773 2.96875 11.3765 2.96875 19.0002C2.96875 26.624 9.1675 32.8227 16.7913 32.8227C24.415 32.8227 30.6138 26.624 30.6138 19.0002C30.6138 17.409 30.3881 15.8534 29.925 14.369C29.83 14.0602 30.0081 13.7277 30.3169 13.6327C30.6256 13.5377 30.9581 13.7159 31.0531 14.0246C31.54 15.6277 31.7894 17.3021 31.7894 19.0002C31.7894 27.2771 25.0563 34.0102 16.7794 34.0102H16.7913Z" fill="#4D4DFF"/>
    <path d="M35.6244 17.5281C35.4819 17.5281 35.3512 17.4806 35.2325 17.3856L29.7106 12.5762C29.4612 12.3625 29.4375 11.9825 29.6512 11.7331C29.865 11.4837 30.245 11.46 30.4944 11.6737L36.0162 16.4831C36.2656 16.6968 36.2894 17.0768 36.0756 17.3262C35.9569 17.4568 35.7906 17.5281 35.6244 17.5281Z" fill="#4D4DFF"/>
    <path d="M26.0063 18.3233C25.8875 18.3233 25.7569 18.2877 25.6619 18.2046C25.4006 18.0146 25.3413 17.6346 25.5313 17.3733L29.6281 11.7683C29.8181 11.5071 30.1981 11.4477 30.4594 11.6377C30.7206 11.8277 30.78 12.2077 30.59 12.469L26.4931 18.074C26.3744 18.2283 26.1963 18.3233 26.0181 18.3233H26.0063Z" fill="#4D4DFF"/>
    <path d="M20.2356 26.98H12.8494C9.56 26.98 8.5625 25.9825 8.5625 22.6931V21.2088C8.5625 17.9194 9.56 16.9219 12.8494 16.9219H20.2356C23.525 16.9219 24.5225 17.9194 24.5225 21.2088V22.6931C24.5225 25.9825 23.525 26.98 20.2356 26.98ZM12.8494 18.1094C10.2131 18.1094 9.75 18.5725 9.75 21.2088V22.6931C9.75 25.3294 10.2131 25.7925 12.8494 25.7925H20.2356C22.8719 25.7925 23.335 25.3294 23.335 22.6931V21.2088C23.335 18.5725 22.8719 18.1094 20.2356 18.1094H12.8494Z" fill="#4D4DFF"/>
    <path d="M12.1016 18.1216C11.7691 18.1216 11.5078 17.8604 11.5078 17.5279V16.0554C11.5078 13.8941 12.0303 11.0322 16.5309 11.0322C19.8678 11.0322 21.5541 12.4691 21.5541 15.3191C21.5541 15.6516 21.2928 15.9129 20.9603 15.9129C20.6278 15.9129 20.3666 15.6516 20.3666 15.3191C20.3666 13.7872 19.9153 12.2197 16.5309 12.2197C13.3484 12.2197 12.6953 13.7516 12.6953 16.0554V17.5279C12.6953 17.8604 12.4341 18.1216 12.1016 18.1216Z" fill="#4D4DFF"/>
    <path d="M16.542 24.3914C15.2001 24.3914 14.0957 23.2989 14.0957 21.9451C14.0957 20.5914 15.1882 19.5107 16.542 19.5107C17.8957 19.5107 18.9763 20.6032 18.9763 21.9451C18.9763 23.287 17.8838 24.3914 16.542 24.3914ZM16.542 20.6982C15.8532 20.6982 15.2832 21.2564 15.2832 21.9451C15.2832 22.6339 15.8413 23.2039 16.542 23.2039C17.2426 23.2039 17.7888 22.6457 17.7888 21.9451C17.7888 21.2445 17.2307 20.6982 16.542 20.6982Z" fill="#4D4DFF"/>
  </g>
  <defs>
    <clipPath id="clip0_68_26491">
      <rect width="38" height="38" fill="white"/>
    </clipPath>
  </defs>
</svg></div>
            <h1 className='md:text-2xl text-2xl font-bold px-4 text-center'>CTV & Video Ads Suite</h1>
            <p className='text-center md:mx-9 mx-3 my-9 md:text-xl text-md'>
            Unlock the efficiency and transparency of programmatic across CTV and video.
            </p>
            <a className='font-medium text-white bg-primary px-4 py-2 mb-6 rounded-3xl text-md hover:bg-white hover:text-primary border border-primary' href="#aboutus"  rel='noreferrer' >Learn More</a>

          </a>

          <a href='#services' className='md:mx-6 mx-1 my-5 md:my-0 md:w-1/3 h-[90%] rounded-xl flex flex-col items-center  shadow-[0_0px_40px_5px_rgba(0,0,0,0.1)] hover:bg-gradient-to-br from-primary  hover:text-white'>

            <div className='p-4 m-6 bg-gray-200 rounded-full'> <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_68_26518)">
    <path d="M30.5199 20.14C30.1874 20.14 29.9262 19.8787 29.9262 19.5462V13.8938C29.9262 7.87312 25.0218 2.96875 19.0012 2.96875C12.9805 2.96875 8.07617 7.87312 8.07617 13.8938V19.5462C8.07617 19.8787 7.81492 20.14 7.48242 20.14C7.14992 20.14 6.88867 19.8787 6.88867 19.5462V13.8938C6.88867 7.22 12.3274 1.78125 19.0012 1.78125C25.6749 1.78125 31.1137 7.22 31.1137 13.8938V19.5462C31.1137 19.8787 30.8524 20.14 30.5199 20.14Z" fill="#4D4DFF"/>
    <path d="M7.48164 25.0087H5.93789C3.88352 25.0087 2.19727 23.3344 2.19727 21.2681V19.1781C2.19727 17.1237 3.87164 15.4375 5.93789 15.4375H7.48164C7.81414 15.4375 8.07539 15.6987 8.07539 16.0312V24.415C8.07539 24.7475 7.81414 25.0087 7.48164 25.0087ZM5.93789 16.625C4.53664 16.625 3.38477 17.765 3.38477 19.1781V21.2681C3.38477 22.6694 4.52477 23.8212 5.93789 23.8212H6.88789V16.625H5.93789Z" fill="#4D4DFF"/>
    <path d="M32.0633 25.0087H30.5195C30.187 25.0087 29.9258 24.7475 29.9258 24.415V16.0312C29.9258 15.6987 30.187 15.4375 30.5195 15.4375H32.0633C34.1177 15.4375 35.8039 17.1119 35.8039 19.1781V21.2681C35.8039 23.3344 34.1295 25.0087 32.0633 25.0087ZM31.1133 23.8212H32.0633C33.4645 23.8212 34.6164 22.6813 34.6164 21.2681V19.1781C34.6164 17.7769 33.4764 16.625 32.0633 16.625H31.1133V23.8212Z" fill="#4D4DFF"/>
    <path d="M21.3516 34.1763C21.0191 34.1763 20.7578 33.915 20.7578 33.5825C20.7578 33.25 21.0191 32.9888 21.3516 32.9888C26.0778 32.9888 29.9253 29.1413 29.9253 24.415C29.9253 24.0825 30.1866 23.8213 30.5191 23.8213C30.8516 23.8213 31.1128 24.0825 31.1128 24.415C31.1128 29.7944 26.7309 34.1763 21.3516 34.1763Z" fill="#4D4DFF"/>
    <path d="M19.82 36.2188H16.3288C15.1531 36.2188 14.2031 35.2688 14.2031 34.0932V33.0719C14.2031 31.8963 15.1531 30.9463 16.3288 30.9463H19.82C20.9956 30.9463 21.9456 31.8963 21.9456 33.0719V34.0932C21.9456 35.2688 20.9956 36.2188 19.82 36.2188ZM16.3288 32.1457C15.8181 32.1457 15.3906 32.5613 15.3906 33.0838V34.105C15.3906 34.6157 15.8063 35.0432 16.3288 35.0432H19.82C20.3306 35.0432 20.7581 34.6275 20.7581 34.105V33.0838C20.7581 32.5732 20.3425 32.1457 19.82 32.1457H16.3288Z" fill="#4D4DFF"/>
    <path d="M17.0054 23.453H13.7279C13.3004 23.453 12.8848 23.2392 12.6354 22.8948C12.3979 22.5623 12.3266 22.1467 12.4573 21.7667C12.8491 20.5673 13.8585 19.8786 14.7491 19.2848C15.7941 18.5723 16.4235 18.0973 16.4235 17.2186C16.4235 16.4111 15.7585 15.7461 14.951 15.7461C14.1435 15.7461 13.4785 16.4111 13.4785 17.2186C13.4785 17.5511 13.2173 17.8123 12.8848 17.8123C12.5523 17.8123 12.291 17.5511 12.291 17.2186C12.291 15.7461 13.4904 14.5586 14.951 14.5586C16.4116 14.5586 17.611 15.758 17.611 17.2186C17.611 18.7742 16.4473 19.5698 15.4141 20.2586C14.6066 20.8048 13.8585 21.3155 13.5854 22.1348C13.621 22.2298 13.6685 22.2655 13.7398 22.2655H17.0173C17.3498 22.2655 17.611 22.5267 17.611 22.8592C17.611 23.1917 17.3498 23.453 17.0173 23.453H17.0054Z" fill="#4D4DFF"/>
    <path d="M23.9513 23.4534C23.6188 23.4534 23.3576 23.1922 23.3576 22.8597V21.6959H20.6263C20.1395 21.6959 19.6882 21.4347 19.4507 21.0191C19.2013 20.5916 19.2013 20.0691 19.4507 19.6534C20.282 18.2166 21.2438 16.6016 22.1345 15.1766C22.4432 14.6778 23.0488 14.4522 23.607 14.6066C24.1651 14.7728 24.557 15.2834 24.557 15.8653V20.5084H25.1388C25.4713 20.5084 25.7326 20.7697 25.7326 21.1022C25.7326 21.4347 25.4713 21.6959 25.1388 21.6959H24.557V22.8597C24.557 23.1922 24.2957 23.4534 23.9632 23.4534H23.9513ZM23.2388 15.7347C23.2388 15.7347 23.1557 15.7584 23.132 15.7941C22.2532 17.2072 21.3032 18.8222 20.472 20.2353C20.4363 20.2828 20.4363 20.3541 20.472 20.4134C20.5076 20.4609 20.567 20.4966 20.6263 20.4966H23.3576V15.8534C23.3576 15.8534 23.322 15.7466 23.2745 15.7347C23.2745 15.7347 23.2507 15.7347 23.2388 15.7347Z" fill="#4D4DFF"/>
  </g>
  <defs>
    <clipPath id="clip0_68_26518">
      <rect width="38" height="38" fill="white"/>
    </clipPath>
  </defs>
</svg>
 </div>
            <h1 className='md:text-2xl text-2xl font-bold px-4 text-center'>Expert Service & Support</h1>
            <p className='text-center md:mx-9 mx-3 my-9 md:text-xl text-md'>
            From setup to yield optimization, our experts are there for you every step of the way.
            </p>
            <a className='font-medium text-white bg-primary px-4 py-2 mb-6 rounded-3xl text-md hover:bg-white hover:text-primary border border-primary' href="#aboutus"  rel='noreferrer' >Learn More</a>
          </a>

          <a href='#appm' className='md:mx-6 mx-1 my-5 md:my-0 md:w-1/3 h-[90%] rounded-xl flex flex-col items-center  shadow-[0_0px_40px_5px_rgba(0,0,0,0.1)] hover:bg-gradient-to-br from-primary  hover:text-white'>

            <div className='p-4 m-6 bg-gray-200 rounded-full'> 
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_68_26544)">
    <path d="M11.0918 29.0938C10.7593 29.0938 10.498 28.8325 10.498 28.5V25.3057C10.498 24.9732 10.7593 24.7119 11.0918 24.7119C11.4243 24.7119 11.6855 24.9732 11.6855 25.3057V28.5C11.6855 28.8325 11.4243 29.0938 11.0918 29.0938Z" fill="#4D4DFF"/>
    <path d="M19 29.0938C18.6675 29.0938 18.4062 28.8326 18.4062 28.5001V22.1113C18.4062 21.7788 18.6675 21.5176 19 21.5176C19.3325 21.5176 19.5938 21.7788 19.5938 22.1113V28.5001C19.5938 28.8326 19.3325 29.0938 19 29.0938Z" fill="#4D4DFF"/>
    <path d="M26.9082 29.0942C26.5757 29.0942 26.3145 28.8329 26.3145 28.5004V18.8936C26.3145 18.5611 26.5757 18.2998 26.9082 18.2998C27.2407 18.2998 27.502 18.5611 27.502 18.8936V28.5004C27.502 28.8329 27.2407 29.0942 26.9082 29.0942Z" fill="#4D4DFF"/>
    <path d="M11.0909 20.2586C10.8296 20.2586 10.5803 20.0804 10.509 19.8073C10.4259 19.4867 10.6278 19.1661 10.9365 19.0829C16.7315 17.6342 21.8496 14.4754 25.7328 9.95106L26.4453 9.1198C26.659 8.87043 27.039 8.84668 27.2884 9.04855C27.5378 9.2623 27.5734 9.6423 27.3596 9.89168L26.6471 10.7229C22.6096 15.4373 17.2778 18.7267 11.2453 20.2348C11.1978 20.2348 11.1503 20.2467 11.1028 20.2467L11.0909 20.2586Z" fill="#4D4DFF"/>
    <path d="M26.9091 14.6062C26.5766 14.6062 26.3154 14.345 26.3154 14.0125V10.0937H22.3848C22.0523 10.0937 21.791 9.8325 21.791 9.5C21.791 9.1675 22.0523 8.90625 22.3848 8.90625H26.9091C27.2416 8.90625 27.5029 9.1675 27.5029 9.5V14.0125C27.5029 14.345 27.2416 14.6062 26.9091 14.6062Z" fill="#4D4DFF"/>
    <path d="M23.6313 35.0312H14.3688C6.27 35.0312 2.96875 31.73 2.96875 23.6313V14.3688C2.96875 6.27 6.27 2.96875 14.3688 2.96875H23.6313C31.73 2.96875 35.0312 6.27 35.0312 14.3688V23.6313C35.0312 31.73 31.73 35.0312 23.6313 35.0312ZM14.3688 4.15625C7.01813 4.15625 4.15625 7.01813 4.15625 14.3688V23.6313C4.15625 30.9819 7.01813 33.8438 14.3688 33.8438H23.6313C30.9819 33.8438 33.8438 30.9819 33.8438 23.6313V14.3688C33.8438 7.01813 30.9819 4.15625 23.6313 4.15625H14.3688Z" fill="#4D4DFF"/>
  </g>
  <defs>
    <clipPath id="clip0_68_26544">
      <rect width="38" height="38" fill="white"/>
    </clipPath>
  </defs>
</svg>
</div>
            <h1 className='md:text-2xl text-2xl font-bold px-4 text-center'>Real-time analytics</h1>
            <p className='text-center md:mx-9 mx-3 my-9 md:text-xl text-md'>
            Track results and trends and get full insight into the effectiveness of your spending in real time
            </p>
            <a className='font-medium text-white bg-primary px-4 py-2 mb-6 rounded-3xl text-md hover:bg-white hover:text-primary border border-primary' href="#aboutus"  rel='noreferrer' >Learn More</a>
          </a>
          
        </div>
      </div>

    </>
  )
}

export default Publishersec;