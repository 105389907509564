import React from 'react'
import '../App.css';
import NavBar from '../components/NavBar';

import Footer from '../components/Footer';
import Ourservices from '../components/Ourservices';


function Services() {
    return (
      <>
      <NavBar/>
      <Ourservices/>
      <Footer/>
      </>
    )
}
export default Services